import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, Button } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import { Modal } from '@mvrb-frontend/shared-ui';

import DownloadModal from './modals/downloadModal';
import DownloadProgress from './modals/downloadProgress';

import { DownloadTypesEnum, DownloadOptions } from '../../constants';

const useStyles = makeStyles(theme => ({
  menu: {
    '& .MuiMenu-list': {
      padding: 0,
    },
  },
  menuItem: {
    padding: 16,
    paddingLeft: 32,
    '&:hover': {
      backgroundColor: '#ECF5F9',
      boxShadow: 'none',
    },
  },
}));

const DownloadDropdownMenu = props => {
  const classes = useStyles();
  const { reviewId, totalComments, hasAttachments } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const [isDownloadModalOpen, setDownloadModalOpen] = useState(false);
  const [alertModalOpen, setAlertModalOpen] = useState<boolean>(false);
  const [alertModalMessage, setAlertModalMessage] = useState('');
  const [downloadType, setDownloadType] = useState(DownloadTypesEnum.NONE);
  const [referenceId, setReferenceId] = useState();

  const openModal = type => {
    if (referenceId && type !== DownloadTypesEnum.COMMENTS) {
      setAlertModalMessage('There is another download in progress');
      setAlertModalOpen(true);
      return;
    }

    setDownloadType(type);
    setDownloadModalOpen(true);
  };
  const closeModal = () => {
    setDownloadType(DownloadTypesEnum.NONE);
    setDownloadModalOpen(false);
    setAnchorEl(null);
  };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Button
        aria-controls="public-review-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        size="large"
        onClick={handleClick}
        endIcon={Boolean(anchorEl) ? <ExpandLess /> : <ExpandMore />}
      >
        {DownloadOptions[0].label}
      </Button>
      <Menu
        id="public-review-menu"
        className={classes.menu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {DownloadOptions.map((option, index) => {
          const { type, label, menuOption, showIfAttachments } = option;
          const visible =
            menuOption &&
            ((hasAttachments && showIfAttachments) || !showIfAttachments);

          return (
            <div key={index}>
              {visible && (
                <MenuItem
                  key={type}
                  onClick={() => openModal(type)}
                  className={classes.menuItem}
                >
                  {label}
                </MenuItem>
              )}
            </div>
          );
        })}
      </Menu>

      <DownloadModal
        isDialogOpen={isDownloadModalOpen}
        downloadType={downloadType}
        onCloseDialog={closeModal}
        reviewId={reviewId}
        totalComments={totalComments}
        setReferenceId={setReferenceId}
      />

      {referenceId && (
        <DownloadProgress
          referenceId={referenceId}
          setReferenceId={setReferenceId}
          setAlertModalOpen={setAlertModalOpen}
          setAlertModalMessage={setAlertModalMessage}
        />
      )}

      <Modal
        isVisible={alertModalOpen}
        titleText={'Warning'}
        modalText={alertModalMessage}
        confirmText={'Accept'}
        onCancel={() => setAlertModalOpen(false)}
        onConfirm={() => setAlertModalOpen(false)}
      />
    </React.Fragment>
  );
};

DownloadDropdownMenu.propTypes = {
  reviewId: PropTypes.string.isRequired,
  totalComments: PropTypes.number.isRequired,
  hasAttachments: PropTypes.bool.isRequired,
};

export default DownloadDropdownMenu;
