import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import moment from 'moment';
import { navigate } from 'gatsby';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Cookies from 'js-cookie';

const useStyles = makeStyles(theme => ({
  dense: {
    margin: 0,
    padding: 0,
    width: '100%',
  },
  draftBox: {
    backgroundColor: '#D5D7D9',
  },
  draftSubheader: {
    color: '#fff',
    padding: 0,
    margin: 0,
  },
}));

const DraftComments = props => {
  const {
    commentSets,
    reviewId,
    commentDueAt,
    responseDueAt,
    lateComment,
    isStaff,//Already have validated if isAdmin and canAdminEdit on the previous component call
  } = props;
  const classes = useStyles();

  const editDraftComments = commentSet => {
    Cookies.set('commentOrigin', 'EditDraft');
    navigate(
      `${process.env.GATSBY_ORS_BASE_URL}/review/${reviewId}/manage-comments/organization/${commentSet.organization.id}/author/${commentSet.author.id}/${isStaff}`,
    );
  };

  const deadlineMessage = lateComment
    ? 'Edits can be made until response deadline: '
    : 'Edits can be made until comment deadline: ';
  const deadlineDate = lateComment
    ? moment.unix(responseDueAt).format('LL')
    : moment.unix(commentDueAt).format('LL');

  return (
    <Box my={2}>
      {commentSets
        .filter(commentSet => commentSet.comments?.length > 0)
        .map(commentSet => {
          return (
            <React.Fragment key={commentSet.id}>
              <Box
                px={2}
                py={1}
                my={1}
                className={classes.draftBox}
                display="flex"
              >
                <Box style={{ flexGrow: 12 }}>
                  <Typography variant="body1" className={classes.dense}>
                    Draft Comments -{' '}
                    {`${commentSet.organization.name}: ${commentSet.author.displayName}`}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.draftSubheader}
                  >
                    {deadlineMessage}
                    {deadlineDate}
                  </Typography>
                </Box>
                <Box>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => editDraftComments(commentSet)}
                  >
                    Edit Draft
                  </Button>
                </Box>
              </Box>
            </React.Fragment>
          );
        })}
    </Box>
  );
};

DraftComments.propTypes = {
  commentSets: PropTypes.array.isRequired,
  reviewId: PropTypes.string.isRequired,
  commentDueAt: PropTypes.number,
  responseDueAt: PropTypes.number,
  lateComment: PropTypes.bool,
  isStaff: PropTypes.bool,
};

export default DraftComments;
