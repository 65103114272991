import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DescriptionIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Link as MuiLink } from '@material-ui/core';

import { Utils } from '@mvrb-frontend/shared-ui';

const useStyles = makeStyles(theme => ({
  item: {
    backgroundColor: '#ECF5F9',
    color: '#1E678F',
    borderRadius: '4px',
    '& :hover': {
      backgroundColor: '#ECECEE',
    },
  },
  itemText: {
    color: '#1E678F',
    fontWeight: 600,
    maxWidth: '100%',
    padding: 0,
    margin: '5px',
  },
  itemSize: {
    color: '#1E678F',
    fontWeight: 600,
    padding: 0,
    margin: '5px',
    minWidth: '64px',
  },
  fileIcon: {
    color: '#E67337',
    marginRight: '16px',
  },
  card: {
    border: '1px solid #ECECEE',
    borderRadius: '4px',
    backgroundColor: '#F6F6F9',
  },
  dense: {
    margin: 0,
    padding: 0,
  },
  collapseButton: {
    color: '#1E678F',
    backgroundColor: '#ECF5F9',
    borderRadius: '4px',
    padding: '2px',
    margin: '5px',
    alignSelf: 'baseline',
    top: '-4px',
  },
  link: {
    fontWeight: 'normal',
    display: 'flex',
    textDecoration: 'none',
    alignItems: 'center',

    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const DetailsDocuments = props => {
  const classes = useStyles();
  const { documents, labels, isPrinting } = props;

  const [expanded, setExpanded] = useState(true);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="h3">Documents</Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <Box style={{ flexGrow: 12, textAlign: 'right' }} className="noPrint">
            <IconButton
              className={classes.collapseButton}
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Collapse in={isPrinting || expanded}>
        <Box my={2}>
          <Card elevation={0} className={classes.card}>
            <Box mx={4} my={3}>
              <Typography variant="body1" className={classes.dense}>
                {documents && documents.length > 0
                  ? `${labels.documentsPrefix} ${documents.length} ${
                      documents.length > 1 ? 'documents' : 'document'
                    }`
                  : labels.noDocuments}
              </Typography>

              {documents && documents.length > 0 && (
                <List dense className={classes.dense}>
                  {documents.map(({ id, name, fileSize, url }) => {
                    return (
                      <Box key={id} my={1} className={classes.item}>
                        <ListItem>
                          <DescriptionIcon className={classes.fileIcon} />
                          <MuiLink
                            href={url}
                            target="_blank"
                            className={classes.link}
                          >
                            <Typography
                              className={classes.itemText}
                              variant="body1"
                            >
                              {name}
                            </Typography>
                            <Typography
                              className={classes.itemSize}
                              variant="body1"
                            >
                              {Utils.getFileSize(fileSize)}
                            </Typography>
                            <GetAppIcon />
                          </MuiLink>
                        </ListItem>
                      </Box>
                    );
                  })}
                </List>
              )}
            </Box>
          </Card>
        </Box>
      </Collapse>
    </Box>
  );
};

DetailsDocuments.defaultProps = {
  isPrinting: false,
};

DetailsDocuments.propTypes = {
  documents: PropTypes.any,
  labels: PropTypes.any.isRequired,
  isPrinting: PropTypes.bool,
};

export default DetailsDocuments;
