import { useState } from 'react';

import { AxiosPrivate } from '../utils/axios';

export const useReviewActive = () => {
  const [hasSetActiveError, setError] = useState(false);
  const [isFetching, setFetching] = useState(false);

  return {
    isFetching,
    hasSetActiveError,
    setReviewAsActive: async (reviewId: string) => {
      try {
        setFetching(true);
        const response = await AxiosPrivate.patch(
          `/reviews/${reviewId}/active`,
        );
        setFetching(false);
        return response;
      } catch (e) {
        setFetching(false);
        setError(e);
      }
    },
  };
};
