import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { Modal } from '@mvrb-frontend/shared-ui';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  boardProceduresLink: {
    cursor: 'pointer',
    color: theme.palette.grey.A400,
  },
}));

const CrownConsultationModal = ({
  isDialogOpen,
  onConfirm,
  procedureRules,
  url,
  hyperLinkTitle,
}) => {
  const classes = useStyles();
  const renderBoardProceduresLink = () => {
    const label: string = hyperLinkTitle;
    return procedureRules ? (
      <Link
        href={url}
        className={classes.boardProceduresLink}
        target="_blank"
      >
        {label}
      </Link>
    ) : (
      label
    );
  };

  return (
    <Modal
      isVisible={isDialogOpen}
      modalText={
        <Box>
          <Typography variant="body1">
            {/* By submitting comments and recommendations to the Online Review
            System, you and/or your organization become a Party to this
            Proceeding and, as such, are expected to follow the
            Boards */}{procedureRules} {renderBoardProceduresLink()}.
          </Typography>
        </Box>
      }
      confirmText="I understand"
      onConfirm={onConfirm}
    />
  );
};

CrownConsultationModal.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  procedureRules: PropTypes.string,
  url: PropTypes.string,
  hyperLinkTitle: PropTypes.string,
};

export default CrownConsultationModal;
