import React, { useEffect, useState } from 'react';

import { navigate } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { Link as MuiLink } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DescriptionIcon from '@material-ui/icons/Description';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import MailIcon from '@material-ui/icons/Mail';

import {
  DueDateAuditTooltip,
  RichTextViewer,
  ReviewType,
  ReviewParadigm,
  Utils,
  PermitMap,
  proponentResponsesDueLabel,
  reviewerCommentsDueLabel,
} from '@mvrb-frontend/shared-ui';
import { useReviews } from '../../hooks';
import { isAdminUser, isStaffUser } from '../../utils/auth';
import { ReviewStatusEnum, OrsRoutes } from '../../constants';

const useStyles = makeStyles(theme => ({
  alertText: {
    padding: '2px',
  },
  collapseButton: {
    color: '#1E678F',
    backgroundColor: '#ECF5F9',
    borderRadius: '4px',
    padding: '2px',
    marginLeft: '5px',
    alignSelf: 'baseline',
  },
  link: {
    fontWeight: 'normal',
    display: 'flex',
    whiteSpace: 'nowrap',
    textDecoration: 'underline',
  },
  linkText: {
    marginBottom: 0,
    paddingBottom: 0,
  },
  card: {
    border: '1px solid #ECECEE',
    borderRadius: '4px',
    backgroundColor: '#F6F6F9',
  },
  dense: {
    margin: 0,
    padding: 0,
  },
  description: {
    '& ol': {
      '& li': {
        'list-style-position': 'inside',
      },
    },
  },
  container: {
    margin: '8px 0px',
  },
}));

const DetailsOverview = props => {
  const classes = useStyles();
  const isStaff = isStaffUser();
  const isAdmin = isAdminUser();
  const { details, labels, fieldAudits, isPrinting = false, canAdminEdit } = props;
  const { getRelatedReviews } = useReviews();
  const [isOverviewOpen, setOverviewOpen] = useState(true);
  const [relatedReviews, setRelatedReviews] = useState([]);

  useEffect(() => {
    (async () => {
      const related = await getRelatedReviews(details.id, (!isStaff && !isAdmin));
      if (related && related.data) {
        setRelatedReviews(related.data);
      }
    })();
  }, []);

  const redistributeReview = () => {
    navigate(OrsRoutes.RedistributeReview.replace(':reviewId', details.id));
  };

  return (
    <React.Fragment>
      {/** Overview */}
      <Box display="flex">
        <Typography variant="h3">Overview</Typography>
        <Box style={{ flexGrow: 12, textAlign: 'right' }} className="noPrint">
          <IconButton
            className={classes.collapseButton}
            onClick={() => setOverviewOpen(!isOverviewOpen)}
          >
            {isOverviewOpen ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
      </Box>
      <Collapse in={isPrinting || isOverviewOpen}>
        {details.description && (
          <Box my={1}>
            <Typography variant="subtitle1">Item Description</Typography>
            <RichTextViewer value={details.description} />
          </Box>
        )}

        <Box my={1}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4} className="printSection">
              <Box mb={2}>
                <Box>
                  <Typography variant="subtitle1">Item Distributed</Typography>

                  {details.distributions?.length > 0 && (
                    <Box>
                      {details.distributions.map(dist => (
                        <Typography
                          key={dist.id}
                          variant="body1"
                          style={{ paddingBottom: 0 }}
                        >
                          {`${moment
                            .unix(dist.distributedAt)
                            .format('MMMM Do')} at ${moment
                            .unix(dist.distributedAt)
                            .format('HH:mm')}`}
                          {dist.distributedFile && (
                            <span>
                              <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                endIcon={
                                  <DescriptionIcon
                                    style={{ color: '#E67337' }}
                                  />
                                }
                                href={dist.distributedFile}
                                style={{ marginTop: 0 }}
                              >
                                View List
                              </Button>
                            </span>
                          )}
                        </Typography>
                      ))}
                    </Box>
                  )}
                </Box>

                {details.distributions?.length == 0 && (
                  <Typography variant="body1">Not distributed yet</Typography>
                )}

                {(isStaff || (isAdmin&&canAdminEdit)) && details.status !== ReviewStatusEnum.DRAFT && (
                  <Box>
                    <Button
                      variant={'outlined'}
                      color={'primary'}
                      onClick={() => redistributeReview()}
                    >
                      Redistribute review
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>

            {details.commentDueAt && (
              <Grid item xs={12} sm={4} className="printSection">
                <Typography variant="subtitle1">
                  {reviewerCommentsDueLabel(details.type)}
                </Typography>
                <Box display="flex">
                  <Typography variant="body1">
                    {moment.unix(details.commentDueAt).format('LL')}
                  </Typography>
                  {fieldAudits?.CommentDueAt?.length > 1 && (
                    <DueDateAuditTooltip
                      dueDateFieldName="Comment"
                      currentDate={details.commentDueAt}
                      extensionDates={fieldAudits.CommentDueAt}
                    >
                      <IconButton
                        className={classes.collapseButton}
                        size="small"
                      >
                        <AccessTimeIcon fontSize="small" />
                      </IconButton>
                    </DueDateAuditTooltip>
                  )}
                </Box>
              </Grid>
            )}

            {details.responseDueAt && (
              <Grid item xs={12} sm={4} className="printSection">
                <Typography variant="subtitle1">
                  {proponentResponsesDueLabel(details.type)}
                </Typography>
                <Box display="flex">
                  <Typography
                    data-test={'review-details-response-due-time'}
                    variant="body1"
                  >
                    {moment.unix(details.responseDueAt).format('LL')}
                  </Typography>
                  {fieldAudits?.ResponseDueAt?.length > 1 && (
                    <DueDateAuditTooltip
                      dueDateFieldName="Response"
                      currentDate={details.responseDueAt}
                      extensionDates={fieldAudits.ResponseDueAt}
                    >
                      <IconButton
                        className={classes.collapseButton}
                        size="small"
                      >
                        <AccessTimeIcon fontSize="small" />
                      </IconButton>
                    </DueDateAuditTooltip>
                  )}
                </Box>
              </Grid>
            )}

            <Grid item xs={12} sm={4} className="printSection">
              <Typography variant="subtitle1">{labels.fileNumber}</Typography>

              {details.fileNumber && (
                <MuiLink
                  href={details.registryUrl}
                  target="_blank"
                  className={classes.link}
                >
                  <Typography variant="body1" className={classes.linkText}>
                    {details.fileNumber}
                  </Typography>
                  <IconButton className={classes.collapseButton} size="small">
                    <OpenInNewIcon fontSize="small" />
                  </IconButton>
                </MuiLink>
              )}

              {details.additionalFileNumbers?.map((afn, index) => (
                <MuiLink
                  key={index}
                  href={afn.registryUrl}
                  target="_blank"
                  className={classes.link}
                >
                  <Typography variant="body1" className={classes.linkText}>
                    {afn.fileNumber}
                  </Typography>
                  <IconButton className={classes.collapseButton} size="small">
                    <OpenInNewIcon fontSize="small" />
                  </IconButton>
                </MuiLink>
              ))}
            </Grid>

            {details.permit && (
              <Grid item xs={12} sm={4} className="printSection">
                <Typography variant="subtitle1">Permit/Licence/EA</Typography>
                {details.permit.map(item => (
                  <Typography
                    variant="body1"
                    className={classes.alertText}
                    key={item}
                  >
                    {PermitMap[item]}
                  </Typography>
                ))}
              </Grid>
            )}

            {details.activities && (
              <Grid item xs={12} sm={4} className="printSection">
                <Typography variant="subtitle1">Activity</Typography>
                {details.activities.map(({ id, name }) => (
                  <Typography
                    variant="body1"
                    className={classes.alertText}
                    key={id}
                  >
                    {name}
                  </Typography>
                ))}
              </Grid>
            )}

            <Grid item xs={12} sm={4} className="printSection">
              <Typography variant="subtitle1">Type of Review</Typography>
              {!!details.reviewParadigm?.length ? (
                details.reviewParadigm.map(item => (
                  <Typography
                    variant="body1"
                    className={classes.alertText}
                    key={item}
                  >
                    {ReviewParadigm.find(el => el.value === item)?.label}
                  </Typography>
                ))
              ) : (
                <Typography variant="body1" className={classes.alertText}>
                  -
                </Typography>
              )}
            </Grid>

            {details.type === ReviewType.InformationRequest && (
              <React.Fragment>
                {!!details.phase && (
                  <Grid item xs={12} sm={4} className="printSection">
                    <Typography variant="subtitle1">
                      IR Phase (Optional)
                    </Typography>
                    <Typography variant="body1" className={classes.alertText}>
                      {details.phase}
                    </Typography>
                  </Grid>
                )}

                {!!details.registryNumber && (
                  <Grid item xs={12} sm={4} className="printSection">
                    <Typography variant="subtitle1">
                      Public Registry Number (Optional)
                    </Typography>
                    <Typography variant="body1" className={classes.alertText}>
                      {details.registryNumber}
                    </Typography>
                  </Grid>
                )}
              </React.Fragment>
            )}

            <Grid container spacing={2} className={classes.container}>
              {details.staffMembers && (
                <Grid item xs={12} sm={4} className="printSection">
                  <Typography variant="subtitle1">Staff Contacts</Typography>
                  <Grid container>
                    {details.staffMembers.map(item => (
                      <Grid
                        key={item.id}
                        item
                        xs={12}
                        sm={12}
                        className="printSection"
                      >
                        <Box display="flex">
                          <Box mr={1}>
                            <MailIcon />
                          </Box>
                          <Box>
                            <Typography
                              variant="body1"
                              className={classes.alertText}
                            >
                              <MuiLink
                                href={`mailto:${item.email}`}
                                target="_blank"
                                className={classes.link}
                              >
                                {item.displayName}
                              </MuiLink>
                            </Typography>
                            <Typography
                              variant="body1"
                              className={classes.alertText}
                            >
                              {item.contact?.officePhone}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}

              {details.primaryContact && (
                <Grid item xs={12} sm={4} className="printSection">
                  <Typography variant="subtitle1">Proponent Contact</Typography>
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <Box display="flex">
                        <Box mr={1}>
                          <MailIcon />
                        </Box>
                        <Box>
                          <Typography
                            variant="body1"
                            className={classes.alertText}
                          >
                            <MuiLink
                              href={`mailto:${details.primaryContact.email}`}
                              target="_blank"
                              className={classes.link}
                            >
                              {details.primaryContact.displayName}
                            </MuiLink>
                          </Typography>
                          <Typography
                            variant="body1"
                            className={classes.alertText}
                          >
                            {details.primaryContact.contact?.cellPhone}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} className="printSection">
              <Card elevation={0} className={classes.card}>
                <Box mx={4} my={2}>
                  <Typography variant="body1" className={classes.dense}>
                    {relatedReviews && relatedReviews.length > 0
                      ? `${labels.documentsPrefix} ${relatedReviews.length} ${
                          relatedReviews.length > 1
                            ? 'related reviews:'
                            : 'related review:'
                        }`
                      : labels.noRelatedItems}
                  </Typography>

                  {relatedReviews && relatedReviews.length > 0 && (
                    <Box>
                      {relatedReviews.map((review, index) => {
                        return (
                          <Box key={index} my={1}>
                            <Button
                              color="secondary"
                              variant="contained"
                              endIcon={<OpenInNewIcon />}
                              size="large"
                              onClick={() =>
                                window.open(`/review/${review.id}`)
                              }
                            >
                              {review.title}
                            </Button>
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </React.Fragment>
  );
};

DetailsOverview.propTypes = {
  details: PropTypes.any.isRequired,
  fieldAudits: PropTypes.any,
  isPrinting: PropTypes.bool,
  labels: PropTypes.any.isRequired,
  canAdminEdit: PropTypes.bool,
};

export default DetailsOverview;
