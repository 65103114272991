import { useState } from 'react';

import { AxiosPrivate } from '../utils/axios';

export const useReviewCompleted = () => {
  const [hasSetCompletedError, setError] = useState(false);
  const [isFetching, setFetching] = useState(false);

  return {
    isFetching,
    hasSetCompletedError,
    setReviewAsCompleted: async (reviewId: string) => {
      try {
        setFetching(true);
        const response = await AxiosPrivate.patch(
          `/reviews/${reviewId}/completed`,
        );
        setFetching(false);
        return response;
      } catch (e) {
        setFetching(false);
        setError(e);
      }
    },
  };
};
