import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import ErrorIcon from '@material-ui/icons/Error';

import { StaticAlert } from '@mvrb-frontend/shared-ui';
import { useUploadResponseAttachments } from '../../../hooks';

const useStyles = makeStyles(theme => ({
  root: {
    border: `2px dashed ${theme.palette.primary.main}`,
    textAlign: 'center',
  },
  icon: {
    fontSize: '72px',
  },
  modalButtons: {
    maxHeight: '30px',
  },
  fileIcon: {
    color: '#E67337',
  },
  viewButton: {
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
}));

function AddAttachmentsModal(props: any) {
  const classes = useStyles();
  const { isModalOpen, onCloseModal, response } = props;
  const {
    isUploadingAttachments,
    uploadingErrors,
    uploadAttachments,
  } = useUploadResponseAttachments();
  const [attachments, setAttachments] = useState<any[]>([]);
  const [attachmentNames, setAttachmentNames] = useState<any[]>([]);
  const [isValid, setValid] = useState(false);
  const selectedRow = response?.index || 1;

  useEffect(() => {
    if (isModalOpen) {
      setAttachmentNames([]);
      setAttachments([]);
    }
  }, [isModalOpen]);

  const handleDrop = files => {
    const names = attachmentNames;
    files.forEach(file => {
      names.push('');
    });
    setAttachmentNames(names);
    setAttachments([...attachments, ...files]);
    validateForm();
  };
  const handleDelete = fileIndex => {
    attachments.splice(fileIndex, 1);
    attachmentNames.splice(fileIndex, 1);
    setAttachmentNames([...attachmentNames]);
    setAttachments([...attachments]);
    validateForm();
  };
  const handleNameChange = (fileIndex, value) => {
    attachmentNames[fileIndex] = value;
    setAttachmentNames([...attachmentNames]);
    validateForm();
  };
  const addAttachments = async event => {
    for (let i = 0; i < attachments.length; i++) {
      const res = await uploadAttachments(
        attachments[i],
        `Response-${
          selectedRow + 1 < 10 ? `0${selectedRow + 1}` : selectedRow + 1
        }-${attachmentNames[i]}`,
      );

      if (res && res.data) {
        await response?.attachments.push({
          id: res.data.id.toLowerCase(),
          name: res.data.name,
        });
      }
    }
    onCloseModal();
  };
  const validateForm = () => {
    if (attachmentNames.length === 0) setValid(false);
    else {
      setValid(!attachmentNames.some(name => !name));
    }
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={onCloseModal}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle>
        <Typography variant="subtitle1">Add Attachments</Typography>
      </DialogTitle>
      <Divider />

      <DialogContent>
        <Box m={1}>
          {uploadingErrors.length > 0 && (
            <Box m={1}>
              <StaticAlert pt={1} mx={4} severity="error" icon={<ErrorIcon />}>
                <ul>
                  {uploadingErrors.map((e, i) => (
                    <li key={i}>{e}</li>
                  ))}
                </ul>
              </StaticAlert>
            </Box>
          )}

          <Dropzone onDrop={files => handleDrop(files)} multiple={false}>
            {({ getRootProps, getInputProps }) => (
              <Box p={2} m={1} className={classes.root} {...getRootProps()}>
                <input {...getInputProps()} multiple={false} />
                <Box>
                  <CloudUploadIcon color="primary" className={classes.icon} />
                </Box>
                <Box>
                  <Typography variant="caption">
                    Drag and Drop your files here…
                  </Typography>
                </Box>
                <Typography variant="caption">
                  Or click here to select files from your computer
                </Typography>
              </Box>
            )}
          </Dropzone>
          <Box my={1} style={{ flexWrap: 'wrap' }} display="flex">
            {attachments.map((file: any, index) => {
              return (
                <Box m={1} key={index}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    startIcon={<DescriptionIcon className={classes.fileIcon} />}
                    endIcon={<DeleteIcon />}
                    disabled={isUploadingAttachments}
                    onClick={() => handleDelete(index)}
                  >
                    {file.name}
                  </Button>
                </Box>
              );
            })}
          </Box>
          <Box my={2}>
            {attachmentNames.map((nameField: any, index) => {
              return (
                <Box my={2} key={index}>
                  <TextField
                    error={nameField === ''}
                    variant="outlined"
                    style={{ width: '100%' }}
                    label="Attachment Name"
                    placeholder="Type in name here"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography variant="body1">
                            Response -
                            {selectedRow + 1 < 10
                              ? `0${selectedRow + 1}`
                              : selectedRow + 1}
                            -
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    onChange={event =>
                      handleNameChange(index, event.target.value)
                    }
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
        `
      </DialogContent>

      <Divider />
      {isUploadingAttachments && <LinearProgress color={'primary'} />}
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          className={classes.modalButtons}
          onClick={onCloseModal}
          disabled={isUploadingAttachments}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={addAttachments}
          className={classes.modalButtons}
          disabled={isUploadingAttachments || !isValid}
        >
          Upload Files
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddAttachmentsModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func,
  response: PropTypes.any,
};

export default AddAttachmentsModal;
