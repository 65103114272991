import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import {
  DownloadFormatOptions,
  CommentSizeBase,
  CommentSizeFactor,
  DownloadTypesEnum,
} from '../../../constants';
import { useReviewDownloads } from '../../../hooks';

const useStyles = makeStyles(theme => ({
  modalButtons: {
    maxHeight: '30px',
  },
  label: {
    fontSize: '12px',
    margin: 5,
    marginTop: 0,
    padding: 0,
  },
}));

const DownloadModal = props => {
  const classes = useStyles();
  const {
    reviewId,
    totalComments,
    isDialogOpen,
    onCloseDialog,
    downloadType,
    setReferenceId,
  } = props;
  const {
    isFetching,
    downloadComments,
    enqueueDownloadAttachments,
  } = useReviewDownloads();
  const [downloadOption, setDownloadOption] = useState(
    DownloadFormatOptions[0].value,
  );

  useEffect(() => {
    if (isDialogOpen) {
      setDownloadOption(DownloadFormatOptions[0].value);
    }
  }, [isDialogOpen]);

  const handleOptionChange = event => {
    setDownloadOption(event?.target?.value.toString());
  };

  const onDownloadClick = () => {
    switch (downloadType) {
      case DownloadTypesEnum.ALL:
        triggerAttachmentsDownload(true);
        break;
      case DownloadTypesEnum.COMMENTS:
        triggerCommentsDownload();
        break;
      case DownloadTypesEnum.ATTACHMENTS:
        triggerAttachmentsDownload(false);
        break;
    }
  };

  const triggerCommentsDownload = async () => {
    const fileExtension = downloadOption;
    const base64File = await downloadComments(reviewId, fileExtension, true);

    const linkSource = `data:application/${fileExtension.substr(
      1,
    )};base64,${base64File}`;

    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = `Reviewer Comments and Proponent Responses${
      fileExtension === 'staff.xlsx' ? '.xlsx' : fileExtension
    }`;

    downloadLink.click();
  };

  const triggerAttachmentsDownload = async (includeComments: boolean) => {
    const downloadRef = await enqueueDownloadAttachments(
      reviewId,
      downloadOption,
      includeComments,
    );

    setReferenceId(downloadRef);
    onCloseDialog();
  };

  const size = (
    CommentSizeBase[downloadOption] +
    totalComments * CommentSizeFactor[downloadOption]
  ).toFixed(2);

  return (
    <Dialog open={isDialogOpen} onClose={onCloseDialog}>
      <DialogTitle>
        <Box>
          <Typography variant="subtitle1">
            Download {downloadType} {`(~${size} MB)`}
          </Typography>
        </Box>
      </DialogTitle>
      <Divider />

      <DialogContent>
        <Box m={1}>
          <Box my={2}>
            <Typography variant="body1">
              Select your comment format below. Your file will be downloaded as
              an single archive file. You can download the comments as:
            </Typography>
            <Box mb={2}>
              <ul>
                {DownloadFormatOptions.map(option => {
                  return (
                    <li
                      key={option.value}
                    >{`${option.label} (${option.value})`}</li>
                  );
                })}
              </ul>
            </Box>
            <Typography variant="body1">
              Note if there are new comments and attachments added after
              downloading, you will need to download a new one.
            </Typography>
            <FormControl fullWidth variant="outlined">
              <p className={classes.label}>Comment Format</p>
              <Select
                value={downloadOption}
                onChange={handleOptionChange}
                name="downloadOption"
              >
                {DownloadFormatOptions.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {`${option.label} (${
                      option.value === 'staff.xlsx' ? '.xlsx' : option.value
                    })`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </DialogContent>

      <Divider />
      {isFetching && <LinearProgress color={'primary'} />}

      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          className={classes.modalButtons}
          onClick={onCloseDialog}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.modalButtons}
          onClick={onDownloadClick}
        >
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DownloadModal.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
  downloadType: PropTypes.string.isRequired,
  setReferenceId: PropTypes.func.isRequired,
  reviewId: PropTypes.string.isRequired,
  totalComments: PropTypes.number.isRequired,
};

export default DownloadModal;
