import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Field, Form as FormikForm, Formik } from 'formik';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DescriptionIcon from '@material-ui/icons/Description';
import ErrorIcon from '@material-ui/icons/Error';
import GetAppIcon from '@material-ui/icons/GetApp';
import OpenInNew from '@material-ui/icons/OpenInNew';

import {
  RenderRichTextField,
  RenderSelectField,
  RichTextViewer,
  StaticAlert,
} from '@mvrb-frontend/shared-ui';
import { AddReplySchema, CommentStatusEnum } from '../../../constants';
import { getUserId, isLoggedIn, isStaffUser } from '../../../utils/auth';
import { useResponses } from '../../../hooks';
import AddAttachmentsModal from '../modals/addAttachmentsModal';
import ViewAttachmentsModal from '../modals/viewAttachmentDetails';
import { useGetUsers } from '../../../hooks';
import delayIcon from '../../../images/delay-icon.png';

const useStyles = makeStyles(theme => ({
  attachmentBtn: {
    cursor: 'initial',
  },
  fileIcon: {
    color: '#E67337',
  },
  downloadIcon: {
    cursor: 'pointer',
  },
  editor: {
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: '#fff',
    borderRadius: '4px',
    verticalAlign: 'top',
    width: '100%',
    height: '245px',
    display: 'flex',
    padding: 0,
    whiteSpace: 'break-spaces',
  },
  attachmentsBox: {
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  formButton: {
    maxHeight: '35px',
    minWidth: '140px',
    boxShadow: 'none',
  },
  commentAuthorAvatar: {
    width: '32px',
    height: '32px',
    fontSize: '16px',
    fontWeight: 'bold',
    backgroundColor: '#009B58',
  },
  responseAuthorAvatar: {
    width: '32px',
    height: '32px',
    fontSize: '16px',
    fontWeight: 'bold',
    backgroundColor: '#05121A',
  },
  userDetails: {
    opacity: 0.4,
  },
  accountIcon: {
    fontSize: '14px',
    marginRight: '4px',
    marginLeft: '4px',
  },
  viewButton: {
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  responseDate: {
    wordSpacing: 1,
  },
  date: {
    opacity: 0.4,
    textAlign: 'right',
  },
  dateContainer:{
    display:'flex',
    alignItems: 'flex-start',
  },
}));

const isStaff = isStaffUser();

const ResponseSection = props => {
  const { 
    responseTree, 
    commentSet, 
    commentRow, 
    refs, 
    isIR, 
    canAddResponses, 
    responseDueAt, 
    lateResponse,
    commentSetPublisehedAt,
  } = props;

  const classes = useStyles();
  const { isSubmitingResponse, resErrors, submitResponses } = useResponses();
  const [users, setUsers] = useState<any[]>([]);
  const [isAttachmentModalOpen, setAttachmentModalOpen] = useState(false);
  const [isAttachmentDetailsOpen, setAttachmentDetailsOpen] = useState(false);
  const { getUsers, users: orgUsers } = useGetUsers();
  const initialValues = {
    organizationId: null,
    proponentId: null,
    description: null,
    index: responseTree.children ? responseTree.children?.length + 1 : 1,
    parentId: responseTree.id,
    attachments: [],
    commentId: commentRow.id,
    status: CommentStatusEnum.PUBLISHED,
  };

  useEffect(() => {
    if (isLoggedIn()) {
      getUsers(commentRow.addressTo?.id);
    }
  }, []);

  useEffect(() => {
    if (orgUsers.length) {
      const reviewerOptions = orgUsers.map(user => {
        return { label: user.displayName, value: user.id };
      });
      setUsers(reviewerOptions);
    }
  }, [orgUsers]);

  const getDescription = res => {
    if (res?.status === CommentStatusEnum.DRAFT || !res?.description)
      return null;
    return res.description;
  };
  const downloadFile = (file: any) => {
    window.open(file.generatedUrl, '_blank');
  };
  const isPrimaryOrganization = userId => {
    return userId === users[0]?.value;
  };
  const getOrgName = userId => {
    return isPrimaryOrganization(userId)
      ? commentSet.organization?.name
      : commentRow.addressTo?.name;
  };
  const submitReply = async values => {
    values.organizationId = isPrimaryOrganization(values.proponentId)
      ? commentSet.organization?.id
      : commentRow.addressTo?.id;

    const res = await submitResponses({ ...values, late: false });

    if (res && res.data) {
      window.location.reload();
    }
  };
  const handleOpenAttachmentsModal = () => {
    setAttachmentModalOpen(true);
  };
  const handleCloseAttachmentsModal = () => {
    setAttachmentModalOpen(false);
  };
  const handleOpenAttachmentDetails = () => {
    setAttachmentDetailsOpen(true);
  };
  const handleCloseAttachmentDetails = () => {
    setAttachmentDetailsOpen(false);
  };

  const getCreatedDate = (date: number) => {
    return moment.unix(date).format('LL');
  };

  const getAttachments = item => {
    return (
      <React.Fragment>
        {item.attachments?.map(file => {
          return (
            <React.Fragment key={file.id}>
              <Grid item xs={3} />
              <Grid item xs={7}>
                <Box id={file.id} my={0.5}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    ref={refs[file.id]}
                    className={classes.attachmentBtn}
                    startIcon={<DescriptionIcon className={classes.fileIcon} />}
                    onClick={() => downloadFile(file.file)}
                    endIcon={<GetAppIcon className={classes.downloadIcon} />}
                  >
                    {file.name}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={2} />
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Grid item xs={3}>
        <Typography variant="subtitle2">Response(s)</Typography>
      </Grid>
      <Grid item xs={7}>
        <Box mb={1}>
          <RichTextViewer value={getDescription(responseTree)} />

          {getAttachments(responseTree)}
        </Box>

        {isIR && responseTree.children && (
          <Box my={1}>
            {responseTree.children.map(child => (
              <Box pb={2} display="flex" key={child.id}>
                <Box>
                  {getOrgName(child.author.id) && (
                    <Avatar
                      className={
                        isPrimaryOrganization(child.author.id)
                          ? classes.commentAuthorAvatar
                          : classes.responseAuthorAvatar
                      }
                    >
                      {getOrgName(child.author.id)[0].toUpperCase()}
                    </Avatar>
                  )}
                </Box>

                <Box ml={2}>
                  <Typography variant="subtitle1">
                    {getOrgName(child.author.id)}
                  </Typography>
                  <Box display="flex" className={classes.userDetails}>
                    <AccountCircleIcon className={classes.accountIcon} />
                    <Typography variant="body2">
                      {child.author.displayName}
                    </Typography>
                    <Typography variant="body2" className={classes.accountIcon}>
                      |
                    </Typography>
                    <DateRangeIcon className={classes.accountIcon} />
                    <Typography
                      variant="body2"
                      className={classes.responseDate}
                    >
                      {moment.unix(child.createdAt).format('MMMM DD, YYYY HH:mm')}
                    </Typography>
                  </Box>
                  <Box>
                    <RichTextViewer value={getDescription(child)} />
                    {getAttachments(child)}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        )}

        {isIR && isLoggedIn() &&
          <Box>
            {canAddResponses && (
              <Box>
                <Typography variant="body1">
                  Add recommendations/responses on behalf of
                </Typography>

                <Formik
                  initialValues={initialValues}
                  validationSchema={AddReplySchema}
                  onSubmit={submitReply}
                >
                  {formik => (
                    <FormikForm>
                      <Box my={1}>
                        <Field
                          name={'proponentId'}
                          component={RenderSelectField}
                          label={'Select Reviewer'}
                          options={users}
                        />
                      </Box>
                      <Box my={1} className={classes.editor}>
                        <Field
                          name={`description`}
                          placeholder={'Add your reply here…'}
                          controls={['bold', 'italic', 'underline', 'bulletList']}
                          fieldLabel={''}
                          component={RenderRichTextField}
                        />
                      </Box>
                      <Box
                        py={0.5}
                        px={2}
                        my={1}
                        className={classes.attachmentsBox}
                      >
                        {formik.values.attachments.length === 0 ? (
                          <Box>
                            <b>Attachment</b>
                            <Button
                              size="small"
                              color="primary"
                              variant="outlined"
                              onClick={() => handleOpenAttachmentsModal()}
                            >
                              Choose file
                            </Button>
                          </Box>
                        ) : (
                          <Button
                            className={classes.viewButton}
                            size="large"
                            endIcon={<OpenInNew color="primary" />}
                            onClick={handleOpenAttachmentDetails}
                          >
                            You have {formik.values.attachments.length}{' '}
                            {formik.values.attachments.length > 1
                              ? 'attachments'
                              : 'attachment'}
                          </Button>
                        )}
                      </Box>
                      <Box my={1} display="flex">
                        <Box flexGrow="2" />
                        <Box>
                          <Button
                            className={classes.formButton}
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={isSubmitingResponse || !formik.isValid}
                          >
                            Submit Reply
                          </Button>
                        </Box>
                      </Box>

                      <ViewAttachmentsModal
                        isModalOpen={isAttachmentDetailsOpen}
                        onCloseModal={handleCloseAttachmentDetails}
                        attachments={formik.values.attachments}
                      />
                      <AddAttachmentsModal
                        isModalOpen={isAttachmentModalOpen}
                        response={formik.values}
                        onCloseModal={handleCloseAttachmentsModal}
                      />
                      <Box>
                        {Boolean(resErrors.length) && (
                          <StaticAlert
                            p={2}
                            severity="error"
                            icon={<ErrorIcon />}
                          >
                            <ul>
                              {resErrors.map((e, i) => (
                                <li key={i}>{e}</li>
                              ))}
                            </ul>
                          </StaticAlert>
                        )}
                      </Box>
                    </FormikForm>
                  )}
                </Formik>
              </Box>
            )}
          </Box>
        }
      </Grid>
      <Grid className={classes.dateContainer} item xs={2}>
        {/* {responseTree.publishedAt >= responseDueAt && lateResponse && commentSetPublisehedAt <= responseDueAt &&
          <img src={delayIcon} style={{ width: '1.25rem', marginTop: '0.3rem', marginRight: '0.3rem' }} />
        } */}
        <Typography
          variant="body1"
          className={classes.date}
        >
          {getCreatedDate(responseTree.updatedAt)}
        </Typography>
      </Grid>
    </React.Fragment>
  );
};

ResponseSection.propTypes = {
  responseTree: PropTypes.any.isRequired,
  commentSet: PropTypes.any,
  commentRow: PropTypes.any,
  refs: PropTypes.any,
  isIR: PropTypes.bool.isRequired,
  canAddResponses: PropTypes.bool,
  responseDueAt: PropTypes.number,
  lateResponse: PropTypes.bool,
  commentSetPublisehedAt: PropTypes.number,
};

export default ResponseSection;
