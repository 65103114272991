import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DescriptionIcon from '@material-ui/icons/Description';

import { Modal } from '@mvrb-frontend/shared-ui';

const useStyles = makeStyles(theme => ({
  fileIcon: {
    color: '#E67337',
  },
  viewButton: {
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  fileButton: {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      cursor: 'default',
    },
  },
}));

function ViewAttachmentsModal(props: any) {
  const classes = useStyles();
  const { isModalOpen, onCloseModal, attachments } = props;

  return (
    <Modal
      isVisible={isModalOpen}
      titleText="Attachments Details"
      confirmText="Close"
      onConfirm={onCloseModal}
      modalText={
        <Box>
          <Typography variant="body1">
            You have {attachments.length}{' '}
            {attachments.length > 1 ? 'attachments' : 'attachment'} for this
            recommendation/response:{' '}
          </Typography>
          {attachments.map((file: any, index) => {
            return (
              <Box m={1} key={index}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  className={classes.fileButton}
                  startIcon={<DescriptionIcon className={classes.fileIcon} />}
                >
                  {file.name}
                </Button>
              </Box>
            );
          })}
        </Box>
      }
    />
  );
}

ViewAttachmentsModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  attachments: PropTypes.array.isRequired,
  onCloseModal: PropTypes.func,
};

export default ViewAttachmentsModal;
