import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from '@mvrb-frontend/shared-ui';

const StatusChangeModal = ({
  isDialogOpen,
  onCancel,
  onConfirm,
  title,
  text,
}) => {
  return (
    <Modal
      isVisible={isDialogOpen}
      titleText={title}
      modalText={text}
      cancelText="No"
      onCancel={onCancel}
      confirmText="Yes"
      onConfirm={onConfirm}
    />
  );
};

StatusChangeModal.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default StatusChangeModal;
